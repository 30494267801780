.not-found-page {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  box-sizing: border-box;

  p {
    color: #131926;
    font-size: 32px;
    font-weight: bold;
    text-align: center;
  }
} 