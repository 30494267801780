.rules-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  box-sizing: border-box;

  .title {
    font-weight: 600;
    font-size: 24px;
    color: #212121;
    margin-top: 40px;
  }
  .session-title {
    font-weight: 600;
    font-size: 16px;
    color: #212121;
    line-height: 1.5;
    margin-top: 24px;
  }
  .content {
    font-size: 16px;
    color: #808080;
    line-height: 1.5;
    margin-top: 8px;
  }
}