.risks-popup {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background-color: white;
  padding: 8px 16px;
  height: calc(100vh - 50px);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  .slide-view {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    padding-bottom: 32px;

    &::-webkit-scrollbar {
      display: none;
    }
    .logo {
      width: 48px;
      height: 48px;
      margin-top: 44px;
    }
    .title {
      margin-top: 16px;
      font-weight: 600;
      font-size: 24px;
      color: #212121;
    }
    .session-title {
      margin-top: 24px;
      font-size: 16px;
      font-weight: 600;
      color: #212121;
      line-height: 1.5;
    }
    .session-content {
      margin-top: 8px;
      font-size: 16px;
      color: #808080;
      line-height: 1.5;
    }
  }
  .gradient-div {
    width: 100%;
    height: 32px;
    margin-top: -32px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%);
  }
  .button-div {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 30px;

    .back-button {
      width: 48px;
      min-width: 48px;
      max-width: 48px;
      height: 48px;
      min-height: 48px;
      max-height: 48px;
      border-radius: 48px;
      border: 1px solid #212121;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;

      img {
        width: 24px;
        height: 24px;
      }
    }
    .proceed-button {
      height: 48px;
      background-color: #212121;
      color: white;
      font-size: 16px;
    }
  }
}