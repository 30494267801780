.beat-component {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;

  .input-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid #E6E6E6;
    border-radius: 16px;
    padding: 16px;
    box-sizing: border-box;

    .info {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      h1 {
        font-size: 14px;
        color: #212121;
      }
      h2 {
        font-size: 14px;
        color: #808080;
      }
    }
    .input-div {
      width: 100%;
      margin-top: 8px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      img {
        width: 24px;
        height: 24px;
      }
      .input {
        flex: 1;
        margin: 0 12px;
  
        input {
          font-size: 32px;
          color: #212121;
          text-align: center;

          &::placeholder {
            color: #808080;
          }
        }
      }
    }
  }
  .buttons-list {
    width: 100%;
    margin: 16px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .button {
      width: calc(50% - 8px);
      height: 64px;
      border-radius: 16px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      img {
        height: 64px;
      }
      .desc {
        display: flex;
        flex-direction: row;
        align-items: center;

        h1 {
          font-style: italic;
          font-size: 18px;
          color: white;
        }
        img {
          width: 16px;
          height: 16px;
          margin: 0 4px;
          opacity: 1.0;
        }
      }
    }
    .bull {
      background-color: #10C29F;
      box-shadow: 0px 4px 8px rgba(16, 194, 159, 0.24);
    }
    .bear {
      background-color: #FF5151;
      box-shadow: 0px 4px 8px rgba(255, 81, 81, 0.24);
    }
    .bull-disable {
      background-color: rgba(#10C29F, 0.25);
    }
    .bear-disable {
      background-color: rgba(#FF5151, 0.25);
    }
    .bull-disable, .bear-disable {
      img {
        opacity: 0.25;
      }
    }
  }
}