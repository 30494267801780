.echart-component {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 32px;

  .head-div {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .content-div {
      display: flex;
      flex-direction: column;

      .t1 {
        font-size: 14px;
        color: #212121;
        margin-right: 4px;
      }
      .t2 {
        font-size: 24px;
        color: #212121;
        margin-top: 4px;

        span {
          display: inline-block;
          font-size: 18px;
          margin-left: 8px;
        }
      }
    }
    .select-button {
      display: flex;
      flex-direction: row;
      align-items: center;
      border: 1px solid #E6E6E6;
      border-radius: 16px;
      padding: 3px 12px 3px 12px;
      margin-left: 4px;

      h2 {
        font-size: 14px;
        color: #212121;
      }
      img {
        width: 16px;
        height: 16px;
        margin-left: 4px;
        margin-right: -4px;
      }
    }
  }
  .echart-div {
    width: 100%;
    height: 254px;
  }
}
.adm-popover-inner-content {
  padding: 0 !important;
}
.echart-popovar-action-list {
  width: 120px;
  display: flex;
  flex-direction: column;
  padding: 0;

  .action-item {
    width: 100%;
    height: 43px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #E6E6E6;
    padding: 0 12px;
    box-sizing: border-box;

    &:last-child {
      border-bottom-style: none;
    }
    h2 {
      font-size: 14px;
      color: #212121;
    }
    img {
      width: 16px;
      height: 16px;
    }
  }
}