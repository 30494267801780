.tool-component {
  width: 100%;
  height: 64px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: white;

  .tab-div {
    height: 32px;
    display: flex;
    flex-direction: row;
    background-color: #F2F2F2;
    border-radius: 32px;

    .tab-item {
      height: 32px;
      font-size: 14px;
      color: #212121;
      line-height: 32px;
      padding: 0 16px;
      border-radius: 32px;
    }
    .tab-item-active {
      background-color: #212121;
      color: white;
    }
  }
  .botton-div {
    display: flex;
    flex-direction: row;
    align-items: center;

    img {
      width: 24px;
      height: 24px;
      margin-left: 16px;
    }
  }
}