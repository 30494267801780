.home-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 16px;
  padding-bottom: 34px;
  box-sizing: border-box;
}
.adm-pull-to-refresh {
  width: 100%;
}