.navbar-component {
  width: 100%;
  height: 44px;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;

  img {
    width: 24px;
    height: 24px;
  }
  h1 {
    color: #212121;
    font-size: 18px;
  }
}