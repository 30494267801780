.info-component {
  width: 100%;
  padding: 16px;
  background-color: #FAFAFA;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  box-sizing: border-box;
  margin-top: 8px;

  .container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .balance-div {
      display: flex;
      flex-direction: column;

      h1 {
        font-size: 16px;
        color: #212121;
      }
      h2 {
        margin-top: 4px;
        font-size: 12px;
        color: #808080;
      }
    }
    .button-div {
      display: flex;
      flex-direction: row;

      img {
        width: 40px;
        height: 40px;
        margin-left: 8px;
      }
    }
  }
}
.transfer-popup {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background-color: white;
  padding: 8px 16px;
  padding-top: 0;
  padding-bottom: 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-y: scroll;

  .tool {
    width: 100%;
    height: 44px;
    min-height: 44px;
    max-height: 44px;
    padding-top: 8px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    position: sticky;
    left: 0;
    top: 0;

    img {
      width: 24px;
      height: 24px;
    }
  }
  .tab-div {
    height: 32px;
    display: flex;
    flex-direction: row;
    background-color: #F2F2F2;
    border-radius: 32px;
    margin-top: 16px;

    .tab-item {
      height: 32px;
      font-size: 14px;
      color: #212121;
      line-height: 32px;
      padding: 0 16px;
      border-radius: 32px;
    }
    .tab-item-active {
      background-color: #212121;
      color: white;
    }
  }
  .assets-div {
    width: 100%;
    margin-top: 24px;
    margin-bottom: -8px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    .assets-item {
      width: calc(50% - 5px);
      border-radius: 16px;
      border: 1px solid #E6E6E6;
      background-color: white;
      box-sizing: border-box;
      padding: 16px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      div {
        display: flex;
        flex-direction: row;
        align-items: center;

        img {
          width: 24px;
          height: 24px;
          border-radius: 24px;
        }
        h1 {
          margin-left: 8px;
          font-size: 16px;
          color: #212121;
        }
      }
      .bonus-div {
        margin-top: 8px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding: 4px 6px;
        border-radius: 8px;
        background-color: #D19143;

        img {
          width: 12px;
          height: 12px;
        }
        h1 {
          margin-left: 2px;
          font-size: 12px;
          color: white;
          font-weight: 500;
        }
      }
    }
    .assets-item-active {
      padding: 15px;
      border: 2px solid #212121;
      background-color: #F2F2F2;
    }
  }
  .amount-container {
    width: 100%;
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    background-color: #FAFAFA;
    border-radius: 16px;

    .input-container {
      width: 100%;
      padding: 16px;
      padding-bottom: 24px;
      box-sizing: border-box;
      border-radius: 16px;
      border: 1px solid #E6E6E6;
      background-color: white;
  
      .input-div {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
  
        .input {
          flex: 1;
  
          input {
            font-size: 32px;
            color: #212121;
  
            &::placeholder {
              color: #808080;
            }
          }
        }
        .asset {
          font-size: 32px;
          color: #BFBFBF;
          margin-left: 12px;
        }
      }
      .max-div {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 8px;
  
        h2 {
          font-size: 14px;
          color: #808080;
        }
        h1 {
          font-size: 14px;
          color: #212121;
          text-decoration-line: underline;
        }
      }
      .minimum {
        margin-top: 8px;
        font-size: 14px;
        color: #808080;
      }
    }
    .fcc-bonus {
      width: 100%;
      padding: 24px 16px;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      h1 {
        font-size: 18px;
        color: #212121;
      }
    }
  }
  .arrow {
    width: 16px;
    height: 16px;
    margin: 0 auto;
    margin-top: 12px;
  }
  .computation-div {
    width: 100%;
    margin-top: 12px;
    background-color: #FAFAFA;
    border-radius: 16px;
    padding: 16px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    .computation-item {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 8px 0;

      h2 {
        font-size: 14px;
        color: #808080;
      }
      h1 {
        font-size: 14px;
        color: #212121;
        white-space: nowrap;
        margin-left: 10px;
      }
    }
  }
  .infomation-div {
    width: 100%;
    margin-top: 12px;
    background-color: #FAFAFA;
    border-radius: 16px;
    padding: 16px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    .info-desc {
      width: 100%;
      padding: 6px 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      img {
        width: 24px;
        height: 24px;
        border-radius: 24px;
      }
      h1 {
        font-size: 16px;
        color: #212121;
        margin: 0 8px;
        flex: 1;
      }
      h2 {
        font-size: 14px;
        color: #FF5151;
      }
    }
    .upgrade-button {
      width: 100%;
      height: 48px;
      border-radius: 48px;
      margin-top: 16px;
      margin-bottom: 8px;
      background-color: #212121;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      h1 {
        font-size: 16px;
        color: white;
      }
      img {
        width: 20px;
        height: 20px;
        margin-left: 4px;
      }
    }
  }
  .next-button {
    margin-top: 48px;
    height: 48px;
    background-color: #212121;
    display: flex;
    align-items: center;
    justify-content: center;
    
    h1 {
      color: white;
      font-size: 16px;
      line-height: 48px;
    }
  }
}
.adm-number-keyboard-key.adm-number-keyboard-key-ok {
  background-color: #212121 !important;
}
.adm-modal {
  z-index: 1040;

  .adm-center-popup-wrap {
    max-width: calc(100vw - 64px);
  }
  .adm-modal-body:not(.adm-modal-with-image) {
    padding: 0 !important;
  }
}
.payment-pin-modal {
  width: calc(100vw - 64px);
  background-color: white;
  border-radius: 16px;
  padding: 0;

  .adm-modal-content {
    padding: 0;
    overflow-y: hidden;

    .modal-content-div {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
  
      .close-button {
        position: absolute;
        top: 14px;
        right: 14px;
        width: 20px;
        height: 20px;
      }
      .title {
        font-size: 20px;
        color: #212121;
        font-weight: 600;
        margin-top: 40px;
      }
      .paymentPIN-div {
        width: 252px;
        height: 42px;
        margin: 40px 0;
        border: 1px solid #E6E6E6;
        border-radius: 6px;
        overflow: hidden;
        display: flex;
        flex-direction: row;

        .single-pin {
          flex: 1;
          height: 100%;
          border-left: 1px solid #E6E6E6;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;

          &:first-child {
            border-left-style: none;
          }
          .point {
            width: 10px;
            height: 10px;
            border-radius: 10px;
            background-color: #212121;
          }
        }
      }
    }
  }
  .adm-modal-footer.adm-space {
    display: none;
  }
}