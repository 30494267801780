.record-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  box-sizing: border-box;

  .data-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 16px;

    .battle-data-item {
      width: 100%;
      padding: 20px 0;
      display: flex;
      flex-direction: column;
      box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.10);

      .item-head-div {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 4px;

        .amount-div {
          flex: 1;
          display: flex;
          flex-direction: row;
          align-items: center;

          img {
            width: 24px;
            height: 24px;
            margin-right: 8px;
          }
          h1 {
            font-size: 16px;
            color: #212121;
          }
        }
        .ongoing-image {
          width: 24px;
          height: 24px;
          margin: 0 8px;
        }
        .image-div {
          width: 36px;
          height: 24px;
          margin: 0 8px;
          position: relative;
  
          .image-bull {
            position: absolute;
            left: 0;
            top: 0;
            width: 24px;
            height: 24px;
            border-radius: 24px;
          }
          .image-bear {
            position: absolute;
            top: 0;
            right: 0;
            width: 24px;
            height: 24px;
            border-radius: 24px;
          }
        }
        .amount {
          flex: 1;
          font-size: 16px;
          color: #212121;
          text-align: right;
        }
      }
      .item-content-div {
        width: 100%;
        margin-top: 8px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        h2 {
          font-size: 12px;
          color: #808080;
        }
      }
    }
    .transfer-data-item {
      width: 100%;
      padding: 16px 0;
      display: flex;
      flex-direction: row;

      .image {
        width: 36px;
        height: 36px;
      }
      .content-div {
        flex: 1;
        margin-left: 12px;
        display: flex;
        flex-direction: column;

        .content-item {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          margin: 3px 0;

          h1 {
            font-size: 16px;
            color: black;
          }
          h2 {
            color: #808080;
            font-size: 12px;
          }
        }
      }
    }
  }
}